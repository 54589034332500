export default {
  // Finnish
  ageGate: 'Tämä sisältö on kypsälle yleisölle.',
  ageGateL2: 'Jatka vain, jos olet oikeutettu.',
  ageGateError: `Valitettavasti et ole ikäinen tämän sisällön tarkastelemiseen.`,
  ageSubmission:
    'Lähettämällä ikääsi vahvistat, että olet tarpeeksi vanha tarkastelemaan tätä sisältöä',
  ageGateConfirm: 'Oletko yli {minAge}-vuotias?',
  ageGateConfirmMessage:
    'Tämä sisältö on tarkoitettu kypsälle yleisölle. Tarkista ikäsi jatkaaksesi. ',
  ageGateConfirmMinAge: 'Kyllä olen {minAge}+',
  ageGateDenyMinAge: 'Ei',
  aiChat: 'AI-chat',
  audioEffect: 'Äänitehoste: ',
  autoScrollOff: 'Automaattinen rekisteröinti pois päältä',
  autoScrollOn: 'Automaattinen rekisteröinti päällä',
  autotranslate: '(Käännetty automaattisesti tekoälyn avulla)',
  accessories: 'Tykötarpeet',
  captions: 'Tekstitykset',
  close: 'Sulkea',
  cookieBannerTextP1: `Käytämme evästeitä tässä videokokemuksessa analysoidaksemme käyttäjän toimintaa anonyymisti. Klikkaamalla "Hyväksyn" hyväksyt näiden evästeiden käytön. Lue lisää `,
  cookieBannerPrivacyText: 'tietosuojakäytännöstämme',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/fi-fi/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '',
  cookieBannerAccept: 'Hyväksyn sen.',
  cookieBannerDeny: 'Ei kiitos',
  copilot: 'Copilot+',
  copy: 'Kopioida',
  dateOfBirth: 'Anna syntymäaikasi',
  day: 'Päivä',
  description: 'Kuvaus',
  details: 'Tiedot',
  devices: 'Laitteet',
  explore: 'Tutkia',
  gamepass: 'Xbox-pelipassi',
  gameInformation: 'Pelin tiedot',
  gaming: 'Pelaamista',
  isoCode: 'fi',
  keyFeatures: 'Tärkeimmät Ominaisuudet',
  language: 'Kieli',
  learnMore: 'Opi lisää',
  live: 'Elää',
  matureContentMessage: 'Pelitaidetta voi katsella vain tässä pelissä.',
  m365: 'M365',
  month: 'Kuukausi',
  months: {
    jan: 'Tam',
    feb: 'Hel',
    mar: 'Maa',
    apr: 'Huh',
    may: 'Tou',
    jun: 'Kes',
    jul: 'Hei',
    aug: 'Elo',
    sep: 'Syy',
    oct: 'Lok',
    nov: 'Mar',
    dec: 'Jou',
  },
  nextSlide: 'Siirry seuraavaan diaan',
  notLive: 'Tämä virta ei ole vielä suorassa lähetyksessä.',
  openTranscript: 'Avaa transkriptio',
  otherProducts: 'Muut tuotteet',
  outdatedBrowser:
    'Näyttää siltä, että selaimesi on vanhentunut. Päivitä nyt katsomaan Mixerin uusimpia striimejä.',
  playVideo: 'Katso video',
  poweredByAzure: 'Voimanlähteenä Azure',
  products: 'Tuotteet',
  productInformation: 'Tuotetiedot',
  promotions: 'Tarjouksia',
  previousSlide: 'Siirry edelliseen diaan',
  purchaseInfo: 'Oston tiedot',
  readMore: 'Lue lisää',
  reload: 'ladata uudelleen',
  reloadToTryAgain: 'Lataa uudelleen yrittääksesi uudelleen',
  selectDay: 'Valitse päivä',
  selectMonth: 'Valitse kuukausi',
  selectYear: 'Valitse vuosi',
  share: 'Jakaa',
  shareDescription: 'Jaa tämä mediakokemus ja online-sivu jonkun kanssa, jonka tunnet.',
  shareDialogTitle: 'Katso nämä upeat ominaisuudet, jotka ovat saatavilla {title}!',
  shopGame: 'Osta {title}',
  shopNow: 'Osta nyt',
  shopSku: 'Osta {title}',
  showMore: 'Näytä lisää',
  showLess: 'Näytä vähemmän',
  showcase: 'Näyteikkuna',
  streamEnded: 'Tämä virta on päättynyt. On Demand -sisältöä ladataan parhaillaan.',
  timeline: 'Aikajana',
  timeStampJumpTo: 'Siirry',
  timeStampMore: 'Enemmän',
  tips: 'Vinkkejä',
  unknownError: 'Hups! Jotain meni pieleen videosoitinta ladattaessa',
  video: 'video',
  voice: 'Ääni: ',
  view: 'Näkymä',
  watchNow: 'Katso nyt',
  windows: 'Windows 11',
  year: 'Vuosi',
  zoovuPolicy: 'Hyväksyttävän käytön käytäntö',
  zoovuPrompt: 'Napsauta kysymyskuplaa käynnistääksesi AI-chat-ominaisuuden',
  zoovuTerms: `Keskustelet tekoälyavustajan kanssa saadaksesi tuotetietoja. Tarkista vastaukset äläkä jaa henkilökohtaisia tietoja.`,
};
