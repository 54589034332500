export default {
  // German
  ageGate: 'Dieser Inhalt richtet sich an Erwachsene.',
  ageGateL2: 'Bitte fahre nur fort, wenn du dazu berechtigt bist.',
  ageGateError: `Es tut uns leid, du bist nicht alt genug um diesen Inhalt zu sehen.`,
  ageSubmission:
    'Indem du dein Alter angibst, bestätigst du, dass du alt genug bist, um diesen Inhalt zu sehen.',
  ageGateConfirm: 'Sind Sie über {minAge}?',
  ageGateConfirmMessage:
    'Dieser Inhalt richtet sich an ein erwachsenes Publikum. Bitte überprüfen Sie Ihr Alter, um fortzufahren. ',
  ageGateConfirmMinAge: 'Ja, ich bin {minAge}+',
  ageGateDenyMinAge: 'Nein',
  aiChat: 'KI-Chat',
  audioEffect: 'Audio-Effekt: ',
  autoScrollOff: 'Autoscroll aus',
  autoScrollOn: 'Autoscroll auf',
  autotranslate: '(Automatisch übersetzt mit KI)',
  accessories: 'Zubehör',
  captions: 'Bildunterschriften',
  close: 'Schließen',
  cookieBannerTextP1: `Wir verwenden Cookies für dieses Videoerlebnis, um die Benutzeraktivität anonym zu analysieren. Durch das Klicken auf "Ich akzeptiere" stimmst du der Verwendung dieser Cookies zu. Weitere Informationen findest du in unseren `,
  cookieBannerPrivacyText: 'Datenschutzbestimmungen',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/de-DE/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '.',
  cookieBannerAccept: 'Ich akzeptiere',
  cookieBannerDeny: 'Nein, danke',
  copilot: 'Copilot+',
  copy: 'Kopieren',
  dateOfBirth: 'Gib dein Geburtsdatum ein.',
  day: 'Tag',
  description: 'Beschreibung',
  details: 'Einzelheiten',
  devices: 'Geräte',
  explore: 'Erkunden',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Spielinformation',
  gaming: 'Gaming',
  isoCode: 'de',
  keyFeatures: 'Hauptmerkmale',
  language: 'Sprache',
  learnMore: 'Mehr erfahren',
  matureContentMessage: 'Dieser Videoinhalt kann nicht im Store angezeigt werden.',
  live: 'Leben',
  m365: 'M365',
  month: 'Monat',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mär',
    apr: 'Apr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dez',
  },
  nextSlide: 'Gehe zum nächsten Slide',
  notLive: 'Der Stream ist noch nicht live.',
  openTranscript: 'Offenes Transkript',
  otherProducts: 'Sonstige Produkte',
  outdatedBrowser:
    'Ups, es sieht so aus, als wäre dein Browser veraltet. Aktualisiere ihn jetzt, um die neuesten Livestreams auf Mixer zu sehen.',
  playVideo: 'Video abspielen',
  poweredByAzure: 'Powered by Azure',
  previousSlide: 'Gehe zum vorherigen Slide',
  products: 'Produkte',
  productInformation: 'Produktinformation',
  promotions: 'Aktionen',
  purchaseInfo: 'Kaufinfo',
  readMore: 'Mehr lesen',
  reload: 'nachladen',
  reloadToTryAgain: 'Bitte laden Sie neu, um es erneut zu versuchen',
  selectDay: 'Tag auswählen',
  selectMonth: 'Monat auswählen',
  selectYear: 'Jahr auswählen',
  share: 'Teilen',
  shareDescription:
    'Teilen Sie diese Medienerfahrung und Online-Seite mit jemandem, den Sie kennen.',
  shareDialogTitle:
    'Schauen Sie sich diese erstaunlichen Funktionen an, die mit {title} verfügbar sind!',
  shopGame: '{title} kaufen',
  shopNow: 'Jetzt kaufen',
  shopSku: '{title} kaufen',
  showMore: 'Zeig Mehr',
  showLess: 'Zeige Weniger',
  showcase: 'Showcase',
  streamEnded: 'Der Stream ist vorbei. Die Aufzeichnung wird gerade hochgeladen.',
  timeline: 'Zeitachse',
  timeStampJumpTo: 'Springen Sie zu',
  timeStampMore: 'Mehr',
  tips: 'Tipps',
  unknownError: 'Hoppla! Beim Laden des Videoplayers ist ein Fehler aufgetreten',
  video: 'video',
  voice: 'Stimme: ',
  view: 'Ansehen',
  watchNow: 'Jetzt Ansehen',
  windows: 'Windows 11',
  year: 'Jahr',
  zoovuPolicy: 'Richtlinie zur akzeptablen Nutzung',
  zoovuPrompt: 'Klicken Sie auf eine Frageblase, um die KI-Chat-Funktion zu starten',
  zoovuTerms: `Sie chatten mit einem KI-Assistenten, um Produktinformationen zu erhalten. Überprüfen Sie die Antworten und geben Sie keine personenbezogenen Daten weiter.`,
};
