import api from '@/api';
import { deDupe, getVideoId } from '@/api/helpers';
import { mapSkuM365 } from '@/api/mappers/m365sku';
import { mapStreamToMedia } from '@/api/mappers/media';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

const state = () => ({
  explore: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
  },
  m365: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
  },
  windows: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
    streamType: process.env.VUE_APP_WINDOWS_STREAM_TYPE,
  },
  devices: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
    streamType: 8,
  },
  gaming: {
    carousel: [],
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
    streamType: 6,
  },
  copilot: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
  },
  showcase: {
    activeEmbed: '',
    activeManifest: {},
    activeIndex: 0,
    carousel: [],
  },
  products: {
    carousel: [],
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
    streamType: 12,
  },
  promotions: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
  },
  tips: {
    carousel: [],
    id: 0,
    loadMore: 'clear',
    maxPage: 0,
    page: 1,
  },
});

const mutations = {
  addMediaPurchaseInfo(state, res) {
    const { id, purchaseInfo } = res;
    const index = state.showcase.carousel.findIndex(i => i.id === id);
    if (index > -1) state.showcase.carousel[index].purchase_info = purchaseInfo;
  },

  addMediaTimestamps(state, res) {
    const { id, timestamps } = res;
    const index = state.showcase.carousel.findIndex(i => i.id === id);
    if (index > -1) state.showcase.carousel[index].timestamps = timestamps;
  },

  // Copilot Surface and N365
  setCopilotMedia(state, item) {
    const { id } = item;
    const index = state.copilot.carousel.findIndex(i => i.id === id);
    state.copilot.carousel[index] = { ...item };
  },

  setActiveShowcaseMedia(state, data) {
    const { activeEmbed, activeManifest, activeIndex } = data;
    state.showcase.activeEmbed = activeEmbed;
    state.showcase.activeManifest = activeManifest;
    state.showcase.activeIndex = activeIndex;
  },

  setBucketCapacities(state, data) {
    const { copilot, devices, explore, gaming, m365 } = data;
    state.copilot.maxPage = Math.floor(copilot / 10);
    state.devices.maxPage = Math.floor(devices / 10);
    state.explore.maxPage = Math.floor(explore / 10);
    state.m365.maxPage = Math.floor(m365 / 10);
    state.gaming.maxPage = Math.floor(gaming / 10);
    state.products.maxPage = 3;
    state.promotions.maxPage = 3;
    state.windows.maxPage = 3;
  },

  setBucketCarousel(state, res) {
    const { data, bucket } = res;
    state[bucket].carousel = data;
  },

  setBucketId(state, params) {
    const { id, bucket } = params;
    state[bucket].id = id;
  },

  setLoadingState(state, res) {
    const { bucket, loadState } = res;
    state[bucket].loadMore = loadState;
  },

  setMedia(state, data) {
    const { bucket, sku } = data;
    const index = state[bucket].carousel.findIndex(i => i.id === sku.id);
    if (index > -1) state[bucket].carousel[index] = { ...sku };
  },

  setMediaManifest(state, res) {
    const { id, manifest } = res;
    const index = state.showcase.carousel.findIndex(i => i.id === id);
    if (index > -1) state.showcase.carousel[index].manifest = manifest;
  },

  setNextPage(state, res) {
    const { bucket, page } = res;
    state[bucket].page = page;
  },

  setSkuLoading(state, data) {
    const { bucket, loading, index } = data;
    state[bucket].carousel[index].loading = loading;
  },
};

const actions = {
  async getBucketCapacities({ commit }) {
    const maxCapacities = await api.getBucketCapacities();
    commit('setBucketCapacities', maxCapacities);
  },

  async getBucketIds({ state, commit }, params) {
    const { retailerId, platform } = params;
    const buckets = await api.getBucketIds(retailerId);

    Object.keys(state).forEach(bucket => {
      if (
        !('id' in state[bucket]) ||
        (bucket === 'windows' && platform === 'Windows') ||
        (bucket === 'devices' && platform === 'Surface')
      )
        return;

      const bucketData = buckets.find(b => b.type === bucket && b.platform === platform);
      if (bucketData) {
        commit('setBucketId', { bucket, id: bucketData.id });
      } else {
        commit('display/setTabState', { name: bucket, val: false }, { root: true });
        commit('setLoadingState', { bucket, loadState: 'done' });
      }
    });
  },

  async getBucketMedia({ state, commit, rootState }, params) {
    NProgress.start();
    const { id, retailer, os, bucket, liveCheck = false } = params;

    const matureContentMinAge =
      rootState.initial.stream.mature_content ?? rootState.retailer.matureContentMinAge;
    let skuItem = state[bucket].carousel.find(i => i.id === id);
    let manifest = {};

    if (
      liveCheck &&
      rootState.display.showLiveStreams &&
      rootState.initial.stream.live_schedule.length > 0 &&
      rootState.initial.stream.platform_details.channel
    ) {
      skuItem.video_live_src =
        rootState.initial.stream.platform_details.platform === 'twitch'
          ? `https://player.twitch.tv/?channel=${rootState.initial.stream.platform_details.channel}`
          : rootState.initial.stream.platform_details.embed_url;
      skuItem.status.live = true;
    }

    const requests = [
      api.getSkuMedia(id, rootState.i18n.country),
      api.getBuyNowUrl(skuItem.id, retailer.id),
      ...(bucket === 'copilot' && skuItem.ageRatingEndpoint
        ? [api.getAgeRating(skuItem.ageRatingEndpoint)]
        : []),
    ];

    const [media, buyNowUrl, ageRating] = await Promise.all(requests);

    // Get video manifest and timestamps
    if (
      media.length &&
      media[0].video_embed?.includes('videoindexer.ai') &&
      ((!!skuItem.age_rating?.min_age && matureContentMinAge > skuItem.age_rating?.min_age) ||
        !skuItem.age_rating?.min_age)
    ) {
      const videoId = getVideoId(media[0].video_embed);
      manifest = await api.getVideoManifest(videoId, rootState.i18n.locale, os);
      if (rootState.display.timestampsEnabled) {
        const timestamps = await api.getTimestamps(
          media[0].video_embed,
          retailer.id,
          rootState.i18n.locale
        );
        if (timestamps.length) skuItem.sku.media_objects[0].timestamps = timestamps;
      }

      // if (
      //   bucket === 'm365' &&
      //   rootState.initial.stream.stream_type.platform === 'M365' &&
      //   rootState.display.purchaseInfoEnabled
      // ) {
      //   const purchaseInfo = await api.getPurchaseInfo(videoId, rootState.i18n.locale);
      //   if (purchaseInfo.length) {
      //     skuItem.sku.media_objects[0].purchase_info = purchaseInfo;
      //   }
      // }
    }

    const updatedSkuItem = {
      ...skuItem,
      buy_now: buyNowUrl,
      ...(ageRating && { age_rating: ageRating }),
      ...(media.length && {
        manifest,
        video_src: media[0].video_embed || '',
      }),
      display: {
        video: !!media[0]?.video_embed || !!skuItem.video_live_src,
        img: !!retailer.offline_image,
        matureMsg: matureContentMinAge < ageRating?.min_age,
        msg: !retailer.offline_image,
        model: bucket === 'explore' && !!skuItem.sku.ar_glb_content_url,
        live: !!(bucket === 'm365' && skuItem.video_live_src),
      },
    };

    commit('setMedia', { sku: updatedSkuItem, bucket });
    NProgress.done();
  },

  async getBucketSchedule({ state, commit, rootState }, params) {
    NProgress.start();
    const { locale, type, liveStreamUrl = false } = params;
    const page = state[type].page;
    commit('setLoadingState', { bucket: type, loadState: 'loading' });

    let contentType;
    switch (type) {
      case 'devices':
        contentType = 'devices';
        break;
      case 'copilot':
        contentType = 'copilot';
        break;
      case 'm365':
        contentType = 'microsoft-365';
        break;
      case 'tips':
        contentType = 'tips';
        break;
      case 'windows':
        contentType = 'windows';
        break;
      default:
        contentType = 'explore';
    }

    const bucketSchedule = await api.getBucketCarousel({
      bucketId: state[type].id,
      bucketType: type,
      contentType,
      locale,
      page,
      ageRatingBoard: rootState.retailer.age_rating_board,
    });

    // Reorder carousel putting selected sku to the front
    if (
      rootState.initial.stream.stream_type.name === 'Category' &&
      rootState.initial.stream.stream_type.platform === 'm365' &&
      type === 'm365' &&
      page === 1
    ) {
      const skuId = rootState.initial.stream.sku.id;

      if (bucketSchedule.some(i => i.id === skuId)) {
        bucketSchedule.sort((a, b) => (a.id === skuId ? -1 : b.id === skuId ? 1 : 0));

        if (liveStreamUrl) {
          bucketSchedule[0].video_live_src = liveStreamUrl;
          bucketSchedule[0].live_schedule = rootState.stream.live_schedule;
          bucketSchedule[0].status.live = true;
        }
      } else {
        const primarySku = mapSkuM365({
          contentType,
          locale,
          stream: rootState.stream,
          liveStreamUrl,
        });
        bucketSchedule.unshift(primarySku);
      }
    }

    if (!bucketSchedule.length) {
      commit('display/setTabDisplay', { name: type, val: false }, { root: true });
      commit('setLoadingState', { bucket: type, loadState: 'done' });
      NProgress.done();
      return;
    }

    commit('setBucketCarousel', {
      data: [...state[type].carousel, ...bucketSchedule],
      bucket: type,
    });
    commit('setNextPage', { bucket: type, page: page === state[type].maxPage ? page : page + 1 });
    commit('setLoadingState', {
      bucket: type,
      loadState: bucketSchedule.length < 10 || page === state[type].maxPage ? 'done' : 'complete',
    });
    NProgress.done();
  },

  async getCarousel({ state, commit, rootState }, params) {
    NProgress.start();
    const { retailerID, stream, type, addStreamToSchedule = false } = params;
    const page = state[type].page;

    let nextPage = page + 1;
    let loadMoreState = 'complete';
    let queryParams = `&streamType=${state[type].streamType}`;

    commit('setLoadingState', { bucket: type, loadState: 'loading' });

    switch (type) {
      case 'gaming':
        if (stream.gamepass_show === 'xbox') {
          queryParams += '&sku.game_pass_xbox=true&order[sku.game_pass_weight]=asc';
        } else if (stream.gamepass_show === 'pc') {
          queryParams += '&sku.game_pass_pc=true&order[sku.game_pass_weight]=asc';
        } else {
          queryParams += '&order[sku.game_pass_weight]=asc';
        }
        break;
      default:
        queryParams += '&order[sku.weight]=asc';
    }

    let data = await api.getCarousel(
      queryParams,
      retailerID,
      page,
      rootState.initial.stream.mature_content ?? rootState.retailer.matureContentMinAge
    );

    // Add initial stream to carousel
    if (page === 1 && addStreamToSchedule) data.unshift(stream);

    // Remove Showcase/PDP stream from the carousel duplication
    if (rootState.initial.stream.stream_type.name === 'PDP') {
      data = data.filter(item => item.id !== stream.id);
    }

    const carouselItems = deDupe([...state[type].carousel, ...data]);

    // If there's no items on the first request, switch buckets off
    if (page === 1 && !carouselItems.length) {
      commit('display/setTabDisplay', { name: type, val: false }, { root: true });
      return;
    }

    // Construct variables before committing
    if (carouselItems.length) {
      commit('setBucketCarousel', { data: carouselItems, bucket: type });
    }

    if (page === state[type].maxPage || data.length < 10) {
      loadMoreState = 'done';
      nextPage = page;
    }

    commit('setNextPage', { bucket: type, page: nextPage });
    commit('setLoadingState', { bucket: type, loadState: loadMoreState });
    NProgress.done();
  },

  async getMediaManifest({ commit }, params) {
    NProgress.start();
    const { id, country, os, url } = params;
    const videoId = getVideoId(url);
    const manifest = await api.getVideoManifest(videoId, country, os);
    commit('setMediaManifest', { id, manifest });
    NProgress.done();
  },

  async getMediaPurchaseInfo({ commit }, params) {
    const { url, id, locale } = params;
    const videoId = getVideoId(url);
    const purchaseInfo = await api.getPurchaseInfo(videoId, locale);
    commit('addMediaPurchaseInfo', { id, purchaseInfo });
  },

  async getMediaTimestamps({ commit }, params) {
    const { id, video, country, retailerId } = params;
    const timestamps = await api.getTimestamps(video, retailerId, country);
    commit('addMediaTimestamps', { id, timestamps });
  },

  async getShowcase({ commit }, params) {
    NProgress.start();
    const { stream } = params;
    let showcaseCarousel = stream.sku.media_objects;

    // Check if stream is live/youtube embed and add tiles to front of showcase carousel
    if (stream.status.live) {
      const liveStream = mapStreamToMedia(stream, 'media');
      showcaseCarousel.unshift(liveStream);
    }

    if (!showcaseCarousel.length) {
      const emptyShowcase = mapStreamToMedia(stream, 'stream');
      showcaseCarousel[0] = { ...emptyShowcase };
    }

    if (showcaseCarousel.length > 0 && showcaseCarousel[0].video_embed) {
      commit('setActiveShowcaseMedia', {
        activeEmbed: showcaseCarousel[0].video_embed,
        activeManifest: showcaseCarousel[0].manifest,
        activeIndex: 0,
      });
    }

    commit('setBucketCarousel', { data: showcaseCarousel, bucket: 'showcase' });
    NProgress.done();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
