export default {
  // Russian
  ageGate: 'Этот контент предназначен для взрослой аудитории.',
  ageGateL2: 'Продолжайте просмотр, только если вы соответствуете этим требованиям.',
  ageGateError: `К сожалению, ваш возраст не соответствует требованиям для просмотра этого контента.`,
  ageSubmission:
    'Указывая свой возраст, вы подтверждаете, что являетесь достаточно взрослым для просмотра этого содержимого',
  ageGateConfirm: 'Вам больше {minAge} лет?',
  ageGateConfirmMessage:
    'Этот контент предназначен для зрелой аудитории. Пожалуйста, проверьте свой возраст, чтобы продолжить.',
  ageGateConfirmMinAge: 'Да, я {minAge}+',
  ageGateDenyMinAge: 'Нет',
  aiChat: 'AI Чат',
  audioEffect: 'Звуковой эффект: ',
  autoScrollOff: 'Автоскролл выключен',
  autoScrollOn: 'Автоскролл на',
  autotranslate: '(Автоматический перевод с помощью ИИ)',
  accessories: 'Принадлежности',
  captions: 'Подписи',
  close: 'Закрыть',
  cookieBannerTextP1: `МЫ используем файлы cookies в этом видео для анонимного анализа действий пользователя. Нажимая кнопку «Согласны», вы подтверждаете свое согласие на использование файлов cookies. См. нашу `,
  cookieBannerPrivacyText: 'Политику конфиденциальности ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/ru-RU/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'для получения дополнительных сведений.',
  cookieBannerAccept: 'Я согласен(-на)',
  cookieBannerDeny: 'Нет, спасибо',
  copilot: 'Copilot+',
  copy: 'копировать',
  dateOfBirth: 'Введите свою дату рождения',
  day: 'День',
  description: 'Описание',
  details: 'Подробности',
  devices: 'Приборы',
  explore: 'исследовать',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Информация об игре',
  gaming: 'Игровой',
  isoCode: 'ru',
  keyFeatures: 'Ключевые особенности',
  language: 'Язык',
  learnMore: 'Подробнее',
  live: 'Прямой эфир',
  matureContentMessage: 'Игровое искусство можно посмотреть только для этой игры.',
  m365: 'M365',
  month: 'Месяц',
  months: {
    янв: 'Янв',
    фев: 'Фев',
    мар: 'Мар',
    апр: 'Апр',
    май: 'Май',
    июнь: 'Июнь',
    июль: 'Июль',
    авг: 'Авг',
    сен: 'Сен',
    окт: 'Окт',
    нояб: 'Нояб',
    дек: 'Дек',
  },
  nextSlide: 'К следующему слайду',
  notLive: 'Эта трансляция пока не началась.',
  openTranscript: 'Открытая стенограмма',
  otherProducts: 'Другие продукты',
  outdatedBrowser:
    'Упс, ваш браузер устарел. Обновите сейчас для просмотра самых последних трансляций с Mixer.',
  playVideo: 'Воспроизвести видео',
  previousSlide: 'К предыдущему слайду',
  products: 'Продукция',
  productInformation: 'Информация о продукте',
  promotions: 'Акции',
  poweredByAzure: 'Работает на Azure',
  purchaseInfo: 'Сведения о покупке',
  readMore: 'Узнать больше',
  reload: 'перезаряжать',
  reloadToTryAgain: 'Пожалуйста, перезагрузите, чтобы повторить попытку',
  selectDay: 'Выберите день',
  selectMonth: 'Выберите месяц',
  selectYear: 'Выберите год',
  share: 'Предоставить общий доступ',
  shareDescription: 'Поделитесь этим медиа-опытом и онлайн-страницей с кем-то, кого вы знаете.',
  shareDialogTitle: 'Ознакомьтесь с этими удивительными функциями, доступными на консоли {title}!',
  shopGame: 'Купить {title}',
  shopNow: 'Купить',
  shopSku: 'Купить {title}',
  showMore: 'Показать еще',
  showLess: 'Показать меньше',
  showcase: 'Витрина',
  streamEnded: 'Трансляция завершена. Запрашиваемый вами контент еще загружается.',
  timeline: 'Хронология',
  timeStampJumpTo: 'Перейти к',
  timeStampMore: 'Подробнее',
  tips: 'Советы',
  unknownError: 'Ой! Что-то пошло не так при загрузке видеоплеера',
  video: 'видео',
  voice: 'Голос: ',
  view: 'Вид',
  watchNow: 'Смотреть сейчас',
  windows: 'Windows 11',
  year: 'Год',
  zoovuPolicy: 'Политика допустимого использования',
  zoovuPrompt:
    'Нажмите на вопросительный пузырь, чтобы активировать функцию чата с искусственным интеллектом.',
  zoovuTerms: `Вы общаетесь в чате с помощником с искусственным интеллектом, чтобы получить информацию о продукте. Проверяйте ответы и не передавайте личные данные.`,
};
