export default {
  // Portuguese
  ageGate: 'Este conteúdo é para um público adulto.',
  ageGateL2: 'Continue apenas se você for elegível.',
  ageGateError: `Desculpe, você não tem idade para assistir este conteúdo.`,
  ageSubmission:
    'Ao ingressar sua idade, você confirma que tem idade suficiente para visualizar este conteúdo',
  ageGateConfirm: 'Você tem mais de {minAge} anos?',
  ageGateConfirmMessage:
    'Este conteúdo é para um público maduro. Por favor, verifique sua idade para continuar. ',
  ageGateConfirmMinAge: 'Sim, eu sou {minAge}+',
  ageGateDenyMinAge: 'Não',
  aiChat: 'Bate-papo com IA',
  audioEffect: 'Efeito de áudio: ',
  autoScrollOff: 'Autoscroll desligado',
  autoScrollOn: 'Autoscroll em',
  autotranslate: '(Traduzido automaticamente usando IA)',
  accessories: 'Acessórios',
  captions: 'Legendas',
  close: 'Fechar',
  cookieBannerTextP1: `Usamos cookies nesta experiência de vídeo para analisar a atividade do usuário de forma anônima. Ao clicar em “Aceito” você concorda com a utilização destes cookies. Veja nossa `,
  cookieBannerPrivacyText: 'Política de Privacidade',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/pt-PT/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: ' para conhecer mais.',
  cookieBannerAccept: 'Aceito',
  cookieBannerDeny: 'Não, obrigado',
  copilot: 'Copilot+',
  copy: 'cópia de',
  dateOfBirth: 'Insira a sua data de nacimento',
  day: 'Dia',
  description: 'Descrição',
  details: 'Detalhes',
  devices: 'Dispositivos',
  explore: 'Explorar',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informações sobre o jogo',
  gaming: 'Jogos',
  isoCode: 'pt',
  keyFeatures: 'Principais Características',
  language: 'Idioma',
  learnMore: 'Saiba mais',
  live: 'Viver',
  matureContentMessage: 'A arte do jogo só pode ser vista para este jogo.',
  m365: 'M365',
  month: 'Mês',
  months: {
    jan: 'Jan',
    feb: 'Fev',
    mar: 'Mar',
    apr: 'Abr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Ago',
    sep: 'Set',
    oct: 'Out',
    nov: 'Nov',
    dec: 'Dez',
  },
  nextSlide: 'Ir para o próximo slide',
  notLive: 'A transmissão ainda nao está ao vivo.',
  openTranscript: 'Transcrição aberta',
  otherProducts: 'Outros Produtos',
  outdatedBrowser:
    'Opa! Parece que seu navegador está desatualizado! Atualize agora para assistir o mais novo em streams no Mixer',
  playVideo: 'Reproduzir vídeo',
  poweredByAzure: 'Alimentado por Azure',
  previousSlide: 'Ir para o slide anterior',
  products: 'Produtos',
  promotions: 'Promoções',
  productInformation: 'Informações sobre o produto',
  purchaseInfo: 'Informação de compra',
  readMore: 'Conheça mais',
  reload: 'recarregar',
  reloadToTryAgain: 'Recarregue para tentar novamente',
  selectDay: 'Selecione o dia',
  selectMonth: 'Selecione o mês',
  selectYear: 'Selecionar ano',
  share: 'Compartilhar',
  shareDescription:
    'Compartilhe esta experiência de mídia e página online com alguém que você conhece.',
  shareDialogTitle: 'Confira esses recursos incríveis disponíveis com {title}!',
  shopGame: 'Compre {title}',
  shopNow: 'Comprar agora',
  shopSku: 'Compre {title}',
  showMore: 'Mostre Mais',
  showLess: 'Mostre Menos',
  showcase: 'Apresentação',
  timeline: 'Linha do tempo',
  tips: 'Dicas',
  streamEnded: 'Este fluxo terminou. O conteúdo sob demanda está sendo carregado no momento.',
  timeStampJumpTo: 'Pule para',
  timeStampMore: 'Mais',
  unknownError: 'Oops! Algo deu errado ao carregar o player de vídeo',
  video: 'video',
  voice: 'Voz: ',
  view: 'Vista',
  watchNow: 'Assista agora',
  windows: 'Windows 11',
  year: 'Ano',
  zoovuPolicy: 'Política de Uso Aceitável',
  zoovuPrompt: 'Clique em um balão de pergunta para iniciar o recurso de bate-papo AI',
  zoovuTerms: `Você está conversando com um assistente de IA para obter informações sobre o produto. Verifique as respostas e não compartilhe dados pessoais.`,
};
