export default {
  // Japaneese
  ageGate: 'このコンテンツは成人向けです。',
  ageGateL2: '対象となる方のみお進みください。',
  ageGateError: `あなたの年齢ではこのコンテンツを見ることができません。`,
  ageSubmission:
    'あなたの年齢を送信することで、このコンテンツを見ることができる年齢であることを確認します。',
  ageGateConfirm: 'あなたは{minAge}歳以上ですか?',
  ageGateConfirmMessage:
    'このコンテンツは、成熟したユーザー向けです。続行するには、年齢を確認してください。',
  ageGateConfirmMinAge: 'はい、私は{minAge}です+',
  ageGateDenyMinAge: 'いいえ',
  aiChat: 'AIチャット',
  audioEffect: 'オーディオエフェクト: ',
  autoScrollOff: '自動スクロールオフ',
  autoScrollOn: '自動スクロール',
  autotranslate: '(AIを使用して自動翻訳)',
  accessories: '小物',
  captions: 'キャプション',
  close: '閉じる',
  cookieBannerTextP1: `このビデオ体験では、ユーザーの活動を匿名で分析するために Cookie を使用しています。"承諾する" をクリックすると、これらのCookieの使用に同意したことになります。 `,
  cookieBannerPrivacyText: 'プライバシーポリシーを確認 ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/ja-jp/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '続きを読む',
  cookieBannerAccept: '承諾する',
  cookieBannerDeny: 'いいえ',
  copilot: 'Copilot+',
  copy: 'コピー',
  dateOfBirth: 'あなたの誕生日を入れてください',
  day: '日',
  description: '解説',
  details: '詳細',
  devices: 'デバイス',
  explore: '探検',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'ゲーム情報',
  gaming: 'ゲーム',
  isoCode: 'ja',
  keyFeatures: '主な機能',
  language: '言語',
  learnMore: '詳細情報',
  live: '住む',
  matureContentMessage: 'ゲームアートはこのゲームでのみ表示できます。',
  m365: 'M365',
  month: '月',
  months: {
    jan: '1月',
    feb: '2月',
    mar: '3月',
    apr: '4月',
    may: '5月',
    jun: '6月',
    jul: '7月',
    aug: '8月',
    sep: '9月',
    oct: '10月',
    nov: '11月',
    dec: '12月',
  },
  nextSlide: '次のスライド',
  notLive: 'このストリームはまだ公開されていません。',
  openTranscript: 'オープントランスクリプト',
  otherProducts: 'その他の製品',
  outdatedBrowser:
    'お使いのブラウザが最新ではないようです。今すぐアップデートして、最新ストリームをご覧ください。',
  playVideo: '動画を再生',
  poweredByAzure: 'Azure によって電力供給',
  previousSlide: '前のスライドに戻る',
  products: 'プロダクツ',
  productInformation: '商品情報',
  promotions: 'プロモーション',
  purchaseInfo: '購入情報',
  readMore: 'もっと読む',
  reload: '積み直す',
  reloadToTryAgain: 'リロードして再試行してください',
  selectDay: '日を選択',
  selectMonth: '月を選択',
  selectYear: '年の選択',
  share: '共有',
  shareDescription: 'このメディア体験とオンラインページを知っている人と共有してください。',
  shareDialogTitle: 'この素晴らしい{title}ビデオをチェックしてください!',
  shopGame: '{title} の購入',
  shopNow: '今すぐ買う',
  shopSku: '{title} の購入',
  showMore: 'もっと表示',
  showLess: '表示を減らす',
  showcase: 'ショーケース',
  streamEnded: 'このストリームは終了しました。オンデマンドコンテンツは現在アップロード中です。',
  timeline: 'タイムライン',
  timeStampJumpTo: 'ジャンプ',
  timeStampMore: 'もっと',
  tips: 'ヒント',
  unknownError: 'おっと！ビデオプレーヤーの読み込み中に問題が発生しました',
  video: 'ビデオ',
  voice: '声：',
  view: '眺める',
  watchNow: '見る',
  windows: 'Windows 11',
  year: '年',
  zoovuPolicy: '利用規約',
  zoovuPrompt: '質問バブルをクリックしてAIチャット機能を開始してください',
  zoovuTerms: `あなたは製品情報のためのAIアシスタントとチャットしています。回答を確認し、個人データを共有しないでください。`,
};
