export default {
  // Mexican Spanish
  ageGate: 'Este contenido es para una audiencia madura.',
  ageGateL2: 'Continúe solo si es elegible.',
  ageGateError: `Lo sentimos, no tienes la edad para ver este contenido.`,
  ageSubmission:
    'Al ingresar tu edad, confirmas que tienes la suficiente edad par ver este contenido',
  ageGateConfirm: '¿Eres mayor de {minAge} años?',
  ageGateConfirmMessage:
    'Este contenido es para un público maduro. Por favor, verifique su edad para continuar. ',
  ageGateConfirmMinAge: 'Sí, soy {minAge}+',
  ageGateDenyMinAge: 'No',
  aiChat: 'Chat de IA',
  audioEffect: 'Efecto de audio: ',
  autoScrollOff: 'Desplazamiento automático desactivado',
  autoScrollOn: 'Desplazamiento automático activado',
  autotranslate: '(Traducido automáticamente usando IA)',
  accessories: 'Accesorios',
  captions: 'Subtítulos',
  close: 'Cerrar',
  cookieBannerTextP1: `Usamos cookies en esta experiencia de video para analizar la actividad del usuario de forma anónima. Al hacer clic en "Acepto", usted acepta el uso de estas cookies. Consulte nuestra `,
  cookieBannerPrivacyText: 'Política de privacidad',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/es-MX/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: ' para leer más.',
  cookieBannerAccept: 'Acepto',
  cookieBannerDeny: 'No, gracias',
  copilot: 'Copilot+',
  copy: 'Dupdo',
  dateOfBirth: 'Escribe tu fecha de nacimiento',
  day: 'Día',
  description: 'Descripción',
  details: 'Detalles',
  devices: 'Dispositivos',
  explore: 'Explorar',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Información del juego',
  gaming: 'Juegos',
  isoCode: 'es',
  keyFeatures: 'Características principales',
  language: 'Idioma',
  learnMore: 'Aprende más',
  live: 'En Vivo',
  matureContentMessage: 'El arte del juego solo se puede ver para este juego.',
  m365: 'M365',
  month: 'Mes',
  months: {
    jan: 'ene',
    feb: 'feb',
    mar: 'mar',
    apr: 'abr',
    may: 'may',
    jun: 'jun',
    jul: 'jul',
    aug: 'ago',
    sep: 'sep',
    oct: 'oct',
    nov: 'nov',
    dec: 'dic',
  },
  nextSlide: 'Ir a la siguiente diapositiva',
  notLive: 'Esta transmisión aún no está en vivo',
  openTranscript: 'Transcripción abierta',
  otherProducts: 'Otros productos',
  outdatedBrowser:
    '¡Ups, parece que tu navegador está desactualizado! Actualízalo ahora para ver los streams más recientes de Mixer',
  playVideo: 'Reproducir vídeo',
  poweredByAzure: 'Desarrollado por Azure',
  products: 'Productos',
  productInformation: 'Información del producto',
  promotions: 'Promociones',
  previousSlide: 'Ir a la diapositiva anterior',
  purchaseInfo: 'Información de compra',
  readMore: 'Leer más',
  reload: 'recargar',
  reloadToTryAgain: 'Vuelva a cargar para volver a intentarlo',
  selectDay: 'Seleccionar día',
  selectMonth: 'Seleccionar mes',
  selectYear: 'Seleccionar año',
  share: 'Cuota',
  shareDescription:
    'Comparte esta experiencia multimedia y esta página en línea con alguien que conozcas.',
  shareDialogTitle: '¡Echa un vistazo a estas increíbles características disponibles con {title}!',
  shopGame: 'Comprar {title}',
  shopNow: 'Comprar ahora',
  shopSku: 'Comprar {title}',
  showMore: 'Mostrar Más',
  showLess: 'Muestra Menos',
  showcase: 'Vitrina',
  streamEnded:
    'Esta transmisión ha finalizado. El contenido a pedido se está cargando actualmente.',
  timeline: 'Cronología',
  timeStampJumpTo: 'Vaya a',
  timeStampMore: 'Más',
  tips: 'Consejos',
  unknownError: '¡Vaya! Algo salió mal al cargar el reproductor de video',
  video: 'video',
  voice: 'Voz: ',
  view: 'Vista',
  watchNow: 'Ver Ahora',
  windows: 'Windows 11',
  year: 'Año',
  zoovuPolicy: 'Política de uso aceptable',
  zoovuPrompt: 'Haga clic en una burbuja de pregunta para iniciar la función de chat de IA',
  zoovuTerms: `Estás chateando con un asistente de IA para obtener información sobre el producto. Verifica las respuestas y no compartas datos personales.`,
};
