import { mapAgeRating } from '@/api/mappers/ageRating.js';
import { normalizeZoovuData } from './normalizeZoovuData';

export const mapStream = (stream, matureContentMinAge) => {
  const minAgeSetting = stream.mature_content?.min_age ?? matureContentMinAge;
  return {
    id: stream.id,
    age_rating: stream.age_rating ? mapAgeRating(stream.age_rating) : null,
    buckets: {
      copilot:
        stream.stream_type.platform.name === 'Surface'
          ? stream.accessories_surface_bucket
          : stream.stream_type.platform.name === 'M365'
          ? stream.accessories_bucket
          : stream.stream_type.platform.name === 'Windows'
          ? stream.gaming_windows_bucket
          : false,
      devices:
        stream.stream_type.platform.name === 'Surface'
          ? stream.devices_bucket
          : stream.stream_type.platform.name === 'Windows'
          ? stream.devices_windows_bucket
          : false,
      explore: stream.explore_bucket,
      gaming: stream.game_pass_bucket,
      m365:
        stream.stream_type.platform.name === 'Surface'
          ? stream.microsoft365_surface_bucket
          : stream.stream_type.platform.name === 'Windows'
          ? stream.m365_windows_bucket
          : stream.microsoft365_bucket,
      products: stream.products_bucket,
      promotions: stream.promotions_bucket,
      tips: stream.stream_type.platform.name === 'Windows' && stream.accessories_windows_bucket,
      windows: stream.windows_bucket,
    },
    content_type: 'stream',
    description_html: stream.description_html ?? '',
    description_plain: stream.description_plain ?? '',
    disable_live_stream: stream.disable_live_stream,
    display: {
      img: false,
      matureMsg: minAgeSetting < stream.age_rating?.min_age,
      live: false,
      model: false,
      msg: false,
      video: false,
    },
    dynamic_shop_now_cta: stream.dynamic_shop_now_cta,
    future_schedule: stream.future_schedule,
    gamepass_show: stream.show_selection,
    live_schedule: stream.live_schedule,
    loading: false,
    manifest: undefined,
    mature_content: stream.mature_content?.min_age,
    msg: undefined,
    pdp_url: stream.pdp_url,
    platform_details: {
      channel: stream.platform_details.channel,
      embed_url: stream.platform_details.embed_url,
      platform: stream.platform_details.platform,
    },
    purchase_info_enabled: stream.purchase_info_enabled,
    retailerId: stream.retailer.id,
    sku: {
      id: stream.sku.id,
      carousel_image: stream.sku.carousel_image?.content_url ?? null,
      game_pass_xbox: stream.sku.game_pass_xbox,
      game_pass_pc: stream.sku.game_pass_pc,
      media_objects: undefined,
      poster_image: stream.sku.poster_image?.content_url ?? null,
      sku_titles: stream.sku.sku_titles ?? null,
      sku_zoovu_codes: stream.sku.sku_zoovu_codes
        ? normalizeZoovuData(stream.sku.sku_zoovu_codes)
        : null,
      tags: stream.sku.tags,
      title: stream.sku.title,
    },
    shop_now_cta: stream.shop_now_cta,
    stackedView: stream.stacked_view,
    status: {
      live: undefined,
      future: undefined,
      is_past: undefined,
      on_demand: undefined,
    },
    stream_descriptions: stream.stream_descriptions,
    stream_shop_now_ctas: stream.stream_shop_now_ctas,
    stream_type: {
      name: stream.stream_type.name,
      platform: stream.stream_type.platform.name,
      platformId: stream.stream_type.platform.id,
    },
    theme: {
      bg_color: stream.bg_color,
      name: stream.theme,
      shop_now_cta_bg_color: stream.shop_now_cta_bg_color,
      shop_now_cta_color: stream.shop_now_cta_color,
    },
    timestamps_enabled: stream.timestamps_enabled,
    title: stream.title,
    type: stream.type,
    video_live_src: undefined,
    video_src: undefined,
  };
};
